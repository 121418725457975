<template>
    <div id="page-lap-penyakit">
      <b-container fluid>
        <b-row>
          <b-col cols="12" sm="12" md="12" lg="12" xl="12">
            <b-card>
              <b-row>
                <b-col cols="12" sm="12" md="12" lg="4" xl="4">
                  <b-form-group label-cols-md="4">
                    <template v-slot:label>
                      Dari
                    </template>
                    <b-form-datepicker
                      style="width: 100%"
                      :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                      no-flip
                      locale="id"
                      v-model="data_search.tanggal_mulai"
                    ></b-form-datepicker>
                  </b-form-group>

                  <b-form-group label-cols-md="4">
                    <template v-slot:label>
                      Sampai
                    </template>
                    <b-form-datepicker
                      style="width: 100%"
                      :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                      no-flip
                      locale="id"
                      v-model="data_search.tanggal_selesai"
                    ></b-form-datepicker>
                  </b-form-group>
                </b-col>
                <b-col cols="12" sm="12" md="12" lg="4" xl="4">

                  <b-form-group label-cols-md="4">
                    <template v-slot:label>
                      Diagnosa
                    </template>
                    <multiselect
                      :options="options_diagnosa"
                      :multiple="false"
                      :searchable="true"
                      :close-on-select="true"
                      :show-labels="false"
                      placeholder="-- Pilih --"
                      label="nama_diagnosa"
                      size="sm"
                      v-model="data_search.diagnosa_id"
                    ></multiselect>
                  </b-form-group>
                </b-col>
                <b-col cols="12" sm="12" md="12" lg="4" xl="4">
                  <b-form-group label-cols-md="4">
                    <template v-slot:label>
                      Kecamatan
                    </template>
                    <multiselect
                      :options="options_kecamatan"
                      :multiple="false"
                      :searchable="true"
                      :close-on-select="true"
                      :show-labels="false"
                      placeholder="-- Pilih --"
                      label="nama_kecamatan"
                      size="sm"
                      v-model="data_search.kecamatan_id"
                      @input="getKelurahan(data_search.kecamatan_id)"
                    ></multiselect>
                  </b-form-group>

                  <b-form-group label-cols-md="4">
                    <template v-slot:label>
                      Kelurahan/Desa
                    </template>
                    <multiselect
                      id=""
                      :options="options_kelurahan"
                      :multiple="false"
                      :searchable="true"
                      :close-on-select="true"
                      :show-labels="false"
                      placeholder="-- Pilih --"
                      size="sm"
                      label="nama_kelurahan"
                      v-model="data_search.kelurahan_id"
                    ></multiselect>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                  <hr>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="12" sm="12" md="12" lg="6" xl="6">
                  <div style="width:100%;display:flex">
                      <div style="width:50%;height:30px;display:flex;justify-content:flex-start;align-items:center">
                        <h6 style="margin:0;font-weight:bold">Puskesmas</h6>
                      </div>
                      <div style="width:50%;height:30px;display:flex;justify-content:flex-end;align-items:center">
                        <b-button variant="outline-primary" size="sm">
                          <b-form-checkbox
                            id="check_puskesmas"
                            name="check_puskesmas"
                            v-model="checked"
                            @change="toggleSelect()"
                          >
                            <span class="pt-1">Pilih Semua</span>
                          </b-form-checkbox>
                        </b-button>
                      </div>
                    </div>
                    <hr>
                    <b-row style="padding-left:7.5px;margin-top:15px;">
                      <b-col v-for="(item, idx) in options_puskesmas" :key="idx" cols="12" sm="12" md="12" lg="6" xl="6">
                          <b-form-checkbox
                            v-model="item.checked"
                            @change="addSelect()"
                          >
                            {{ item.nama_puskesmas }}
                          </b-form-checkbox>
                      </b-col>
                    </b-row>
                </b-col>
                <b-col cols="12" sm="12" md="12" lg="6" xl="6">
                  <div style="width:100%;display:flex">
                      <div style="width:50%;height:30px;display:flex;justify-content:flex-start;align-items:center">
                        <h6 style="margin:0;font-weight:bold">Poli / Ruangan</h6>
                      </div>
                      <div style="width:50%;height:30px;display:flex;justify-content:flex-end;align-items:center">
                        <b-button variant="outline-primary" size="sm">
                          <b-form-checkbox
                            id="check_poli"
                            name="check_poli"
                            v-model="checked_poli"
                            @change="toggleSelectPoli()"
                            
                          >
                            <span class="pt-1">Pilih Semua</span>
                          </b-form-checkbox>
                        </b-button>
                      </div>
                    </div>
                    <hr>
                    <b-row style="padding-left:7.5px;margin-top:15px;">
                      <b-col v-for="(item, idx) in options_poli" :key="idx" cols="12" sm="12" md="12" lg="6" xl="6">
                          <b-form-checkbox
                            v-model="item.checked"
                            @change="addSelectPoli()"
                          >
                            {{ item.nama_poli }}
                          </b-form-checkbox>
                      </b-col>
                    </b-row>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                  <hr>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                  <div style="display:flex;justify-content:flex-start">
                    <b-button @click="getDataKunjungan()" variant="primary" class="mr-2">Tampilkan</b-button>
                    <!-- <b-button variant="success" class="mr-2">Export to Excel</b-button>
                    <b-button variant="warning">Reset</b-button> -->
                  </div>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                  <hr>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                  <h5 style="text-align:center"><strong>LAPORAN HARIAN - PENYAKIT</strong></h5>
                </b-col>
              </b-row>

              <!-- <b-row style="margin-top:15px;">
                <b-col cols="12" sm="12" md="12" lg="6" xl="6">
                  <b-table-simple borderless class="custom-table">
                    <b-tbody>
                      <b-tr>
                        <b-td style="width:140px;">Puskesmas</b-td>
                        <b-td style="width:15px;">:</b-td>
                        <b-td>-</b-td>
                      </b-tr>

                      <b-tr>
                        <b-td>Tanggal</b-td>
                        <b-td>:</b-td>
                        <b-td>-</b-td>
                      </b-tr>

                      <b-tr>
                        <b-td>Wilayah</b-td>
                        <b-td>:</b-td>
                        <b-td>-</b-td>
                      </b-tr>

                      <b-tr>
                        <b-td>Total</b-td>
                        <b-td>:</b-td>
                        <b-td>-</b-td>
                      </b-tr>
                    </b-tbody>
                  </b-table-simple>
                </b-col>
                <b-col cols="12" sm="12" md="12" lg="6" xl="6">
                  <b-table-simple borderless class="custom-table">
                    <b-tbody>
                      <b-tr>
                        <b-td style="width:140px;">Poli/Ruangan</b-td>
                        <b-td style="width:15px">:</b-td>
                        <b-td>-</b-td>
                      </b-tr>

                      <b-tr>
                        <b-td>Diagnosa</b-td>
                        <b-td>:</b-td>
                        <b-td>-</b-td>
                      </b-tr>

                      <b-tr>
                        <b-td>Kecamatan</b-td>
                        <b-td>:</b-td>
                        <b-td>-</b-td>
                      </b-tr>

                      <b-tr>
                        <b-td>Kelurahan</b-td>
                        <b-td>:</b-td>
                        <b-td>-</b-td>
                      </b-tr>

                      <b-tr>
                        <b-td>RT/RW</b-td>
                        <b-td>:</b-td>
                        <b-td>-</b-td>
                      </b-tr>
                    </b-tbody>
                  </b-table-simple>
                </b-col>
              </b-row> -->

              <b-row>
                <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                  <b-table-simple striped>
                    <b-thead>
                      <b-tr>
                        <b-th rowspan="2">No.</b-th>
                        <b-th rowspan="2">Kode Diagnosa</b-th>
                        <b-th rowspan="2">Diagnosa</b-th>
                        <b-th style="text-align:center" colspan="2">Jumlah</b-th>
                        <b-th rowspan="2">Total</b-th>
                      </b-tr>

                      <b-tr>
                        <b-th style="text-align:center">Laki-laki</b-th>
                        <b-th style="text-align:center">Perempuan</b-th>
                      </b-tr>
                    </b-thead>
                    <b-tbody>
                      <b-tr v-for="(item, idx) in items" :key="idx">
                        <b-td>{{ idx+1 }}</b-td>
                        <b-td>{{ item.kd_diag }}</b-td>
                        <b-td>{{ item.nm_diag }}</b-td>
                        <b-td style="text-align:center">{{ item.laki_laki }}</b-td>
                        <b-td style="text-align:center">{{ item.perempuan }}</b-td>
                        <b-td>{{ item.total }}</b-td>
                      </b-tr>
                    </b-tbody>
                  </b-table-simple>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </template>
  
  <script>
  import Multiselect from "vue-multiselect";
  export default {
    name: "kunjungan_pasien",
    components: {
      Multiselect
    },
    data() {
      return {
        showing: false,
        variant: "success",
        msg: "",
        value: null,
        options_kecamatan: [],
        options_kelurahan: [],
        options_diagnosa: [],
        data_search: {
          tanggal_mulai: null,
          tanggal_selesai: null,
          kecamatan_id: null,
          kelurahan_id: null,
          diagnosa_id: null,
          ms_diagnosa_id: null,
          ms_kecamatan_id: null,
          ms_kelurahan_id: null,
          bulk_ms_poli_id: [],
          bulk_puskesmas_id: [],
          halaman: 0,
          jumlah: 1000,
        },
        items: [],
        checked: false,
        checked_poli: "",
        select_puskesmas: [],
        select_poli: [],
        options_puskesmas: [],
        options_poli: [],
      };
    },
    computed: {
      sortOptions() {
        // Create an options list from our fields
        return this.fields
          .filter((f) => f.sortable)
          .map((f) => {
            return { text: f.label, value: f.key };
          });
      },
      filterOn() {
        // console.log(this.fields.map(el => el.key))
        return this.fields.map(el => el.key)
      },
    },
    activated(){
      this.data_search.tanggal_mulai = this.$moment(new Date()).format("YYYY-MM-DD")
      this.data_search.tanggal_selesai = this.$moment(new Date()).format("YYYY-MM-DD")
      this.getKecamatan()
      this.getData();
      this.getDataKunjungan()
    },
    methods: {
      onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length;
        this.currentPage = 1;
      },
      async getData(){
        let vm = this
        let diagnosa = await vm.$axios.post("/ms_diagnosa/list")
        if(diagnosa.data.data.length){
            vm.options_diagnosa = diagnosa.data.data
            for (let i = 0; i < vm.options_diagnosa.length; i++) {
                let x = vm.options_diagnosa[i];
                x.nama_diagnosa = `${x.kd_diag} - ${x.nm_diag}`
            }
        }
        let puskesmas = await vm.$axios.post("/puskesmas/list")
        if(puskesmas.data.data.length){
            vm.options_puskesmas = puskesmas.data.data
            for (let i = 0; i < vm.options_puskesmas.length; i++) {
                let x = vm.options_puskesmas[i];
                x.checked = false
            }
        }
        let poli = await vm.$axios.post("/ms_poli/list")
        if(poli.data.data.length){
            vm.options_poli = poli.data.data
            for (let i = 0; i < vm.options_poli.length; i++) {
                let x = vm.options_poli[i];
                x.checked = false
            }
        }
      },
      async getKecamatan(){
        let vm = this
        let kecamatan = await vm.$axios.post("/ms_kecamatan/list")
        vm.options_kecamatan = kecamatan.data.data
      },
      async getKelurahan(x){
        let vm = this
        vm.data_search.kelurahan_id = null
        let kelurahan = await vm.$axios.post("/ms_kelurahan/list", {ms_kecamatan_id: x.ms_kecamatan_id})
        vm.options_kelurahan = kelurahan.data.data
      },
      getDataKunjungan(){
        let vm = this
        if(vm.data_search.kecamatan_id){
          vm.data_search.ms_kecamatan_id = vm.data_search.kecamatan_id.ms_kecamatan_id
        }
        if(vm.data_search.kelurahan_id){
          vm.data_search.ms_kelurahan_id = vm.data_search.kelurahan_id.ms_kelurahan_id
        }
        if(vm.data_search.diagnosa_id){
          vm.data_search.ms_diagnosa_id = vm.data_search.diagnosa_id.ms_diagnosa_id
        }
        if(vm.select_puskesmas.length){
          vm.data_search.bulk_puskesmas_id = vm.select_puskesmas
        }
        if(vm.select_poli.length){
          vm.data_search.bulk_ms_poli_id = vm.select_poli
        }
        vm.$axios.post("/kunjungan/list_laporan_penyakit", vm.data_search)
        .then((res) => {
          console.log(res, 'ini respon');
          if(res.data.data.length){
            vm.items = res.data.data
            for (let i = 0; i < vm.items.length; i++) {
              let x = vm.items[i];
              x.total = x.laki_laki + x.perempuan
            }
          }else {
            vm.items = []
            vm.$store.commit('set_alert', {msg: "TIDAK ADA DATA", variant: "danger", showing: true})
          }
          
        })
        .catch((err) => {
          vm.$store.commit('set_alert', {msg: err.message, variant: "danger", showing: true})
        })
      },
      toggleSelect: function () {
            let select = this.checked;
            console.log(this.checked);
            this.options_puskesmas.forEach(function (data) {
                data.checked = select;
            });
            this.select_puskesmas = []
            for (let i = 0; i < this.options_puskesmas.length; i++) {
              let x = this.options_puskesmas[i];
              if(x.checked == true){
                this.select_puskesmas.push(x.id)
              }
            }
            this.$forceUpdate()
        },
        toggleSelectPoli: function () {
            let select = this.checked_poli;
            // console.log(this.checked_poli);
            this.options_poli.forEach(function (data) {
                data.checked = select;
            });
            this.select_poli = []
            for (let i = 0; i < this.options_poli.length; i++) {
              let x = this.options_poli[i];
              if(x.checked == true){
                this.select_poli.push(x.ms_poli_id)
              }
            }
            this.$forceUpdate()
        },
        addSelect(){  
          let vm = this
          vm.select_puskesmas = []
          for (let i = 0; i < vm.options_puskesmas.length; i++) {
              let x = vm.options_puskesmas[i];
              if(x.checked == true){
                console.log(x, 'ini x');
                vm.select_puskesmas.push(x.id)
              }
            }
            
        },
        addSelectPoli(){  
          let vm = this
          vm.select_poli = []
          for (let i = 0; i < vm.options_poli.length; i++) {
              let x = vm.options_poli[i];
              if(x.checked == true){
                vm.select_poli.push(x.ms_poli_id)
              }
            }
        },
      triggerAlert(event) {
        let vm = this;
        vm.$store.commit("set_alert", event);
        vm.getDatas()
      },
    },
  };
  </script>
  <style>
  #page-lap-penyakit .custom-table td{
    padding:0 !important;
    font-size:14px;
    font-weight:bold;
    border:hidden !important;
  }

  #page-lap-penyakit .custom-control-label{
    padding-top:2.5px
  }
  </style>
  